import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../../components/Layout"
import HeadMeta from "../../components/HeadMeta"
import PageHeader from "../../components/PageHeader"
import Breadcrumb from "../../components/Breadcrumb"
import SearchPager from "../../components/SearchPager";

import { useLunr } from '../../lib/hooks';

const Advanced = ({ data }) => {
  const [searchBy, setSearchBy] = React.useState('name');
  const [searchString, setSearchString] = React.useState('');
  const [searchYearBy, setSearchYearBy] = React.useState('');
  const [searchYear, setSearchYear] = React.useState(0);
  const [searchParty, setSearchParty] = React.useState('');
  const [numResults, setNumResults] = React.useState(10);

  const [searched, setSearched] = React.useState(false);
  const [results, setResults] = React.useState([]);
  const [startAt, setStartAt] = React.useState(0);
  const [searchReady, _runSearch, runComplexSearch] = useLunr('en', true);

  const onSubmit = (e) => {
    e.preventDefault();
    setStartAt(0);
    setSearched(true);
    const chunks = {
      Type: 'Parliamentarian',
      Name: null,
      q: null,
      PublicationDate: null,
      ServiceDate: null,
      LifetimeDate: null,
      PartyName: null,
    };
    if (searchString.length > 0) {
      if (searchBy === 'name') {
        chunks.Name = searchString;
      } else {
        chunks.q = searchString;
      }
    }
    if (searchYear > 0) {
      if (searchYearBy === 'pub_date') {
        chunks.PublicationDate = searchYear;
      } else if (searchYearBy === 'service_date') {
        chunks.PublicationDate = searchYear;
      } else if (searchYearBy === 'lifetime_date') {
        chunks.LifetimeDate = searchYear;
      } else {
        if (chunks.q && chunks.q.length) {
          chunks.q = `${chunks.q} searchYear`;
        } else {
          chunks.q = searchYear;
        }
      }
    }
    if (searchParty.length > 0) {
      chunks.PartyName = searchParty;
    }

    runComplexSearch(chunks, {})
      .then(res => setResults(res))
      .catch(e => console.error(e));
  };

  const onReset = () => {
    setSearched(false);
    setSearchBy('name');
    setSearchString('');
    setSearchYearBy('');
    setSearchYear(0);
    setSearchParty('');
    setNumResults(10);
    setResults([]);
    setStartAt(0);
  };

  const partyNames = data.allPartyCsv.group
    .map(group => group.nodes[0].fields.description);
  
  const firstItemIndex = startAt
  const lastItemIndex = startAt + numResults
  
  const pagedResults =
    results.length > 0 &&
    results.slice(firstItemIndex, lastItemIndex).map((r, index) => (
      <li key={index}>
        <Link to={`/${r.Slug}`}>
          {r.Type}
          {": "}
          {r.Name}
        </Link>
      </li>
    ))

  return (
    <Layout>
      <HeadMeta pageTitle={"Advanced Search"} />
      <PageHeader>Advanced Search</PageHeader>
      <Breadcrumb
        route={[
          { name: "Parliamentary Papers", href: "/" },
          { name: "Search", href: "/search/" },
          { name: "Advanced Search", href: "/search/advanced/" },
        ]}
      />
      <form onSubmit={onSubmit}>
        <div className="formRow">
          <label>Search by</label>
          <select value={searchBy} onChange={(e) => setSearchBy(e.currentTarget.value)}>
            <option value="name">Name</option>
            <option value="keyword">Keyword</option>
          </select>
          <input type="search" value={searchString} onChange={(e) => setSearchString(e.currentTarget.value)} />
        </div>
        <div className="formRow">
          <label>Date range</label>
          <select value={searchYearBy} onChange={(e) => setSearchYearBy(e.currentTarget.value)}>
            <option value="">Any date type</option>
            <option value="pub_date">Publication date</option>
            <option value="service_date">Political service date</option>
            <option value="lifetime_date">Birth or death year</option>
          </select>
          <input type="number" min={1788} max={(new Date()).getFullYear()} onChange={(e) => setSearchYear(parseInt(e.currentTarget.value, 10))} />
        </div>
        <div className="formRow">
          <label>Political party</label>
          <select value={searchParty} onChange={(e) => setSearchParty(e.currentTarget.value)}>
            <option key="__empty__">- Select a party -</option>
            {partyNames.map(pn => (
              <option value={pn} key={pn}>
                {pn}
              </option>
            ))}
          </select>
        </div>
        <div className="formRow">
          <label>Number of results</label>
          <select value={numResults} onChange={(e) => setNumResults(parseInt(e.currentTarget.value, 10))}>
            <option>10</option>
            <option>15</option>
            <option>20</option>
            <option>30</option>
          </select>
        </div>
        <div className="formRow">
          <button type="submit" disabled={!searchReady} className="submitButton">
            Search
          </button>
          <button type="reset" onClick={onReset}>
            Reset
          </button>
        </div>
      </form>
      { searched && results.length > 0 && (
        <>
          <h2>Results</h2>
          <ol start={firstItemIndex + 1}>
            { pagedResults }
          </ol>
          <SearchPager
            firstItemIndex={firstItemIndex}
            lastItemIndex={lastItemIndex}
            pageLength={numResults}
            onSetStart={(newStart) => setStartAt(newStart)}
            resultsCount={results.length}
          />
        </>
      )}
      { searched && !pagedResults.length && (
        <>
          <h2>No results found.</h2>
          <p>Sorry, no records matching the query were found.</p>
        </>
      )}
      <h2>Browse</h2>
      <ul>
        <li><Link to={`/parliamentarians/`}>Browse by parliamentarian surname</Link></li>
        <li><Link to={`/electorates/`}>Browse by electorate</Link></li>
      </ul>
    </Layout>
  );
};

export default Advanced;

export const query = graphql`
  query AdvancedSearchQUery {
    allPartyCsv(filter: { fields: { description: { nin: [null, ""] } } }) {
      group(field: fields___description, limit: 1) {
        nodes {
          fields {
            description
          }
        }
      }
    }
  }
`
